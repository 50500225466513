
// Header separators:
.ag-theme-alpine {
  --ag-header-column-separator-display: block;
  --ag-header-column-separator-color: rgba(102,45,145,0.25); // change to import ref of _colors.scss
  --ag-header-column-separator-width: 1px;
  --ag-header-column-separator-height: 35%;
  --ag-header-column-resize-handle-height: 80%;
  --ag-header-column-resize-handle-width: 4px;
}
.ag-header-cell-resize::after {
  background-color: var(--ag-header-column-separator-color) !important;
}

// Top left cell of grid or all top cells of pinned left columns.
// Used to add in a border to the right of the top left cell if
// keeping the "#" header text (rather than discarding it).
.ag-pinned-left-header, .ag-header-row-column {
  border-right: none;
}

// Describes the main ag-grid table body area - not including
// top or side headers or sidebar, but including pinned rows / 
// columns. Used to add back in border between the main table
// and the left sidebar filters so that the table is not "open"
// on the left side.
.ag-body-viewport {
  border-left: 1px solid lightgrey !important;
}

// Outer grid container (outside of sidebar). Used to remove the
// outermost border on top of the table headers and to the left
// outside of the sidebar.
.ag-theme-alpine .ag-root-wrapper {
  border: none;
}

// Side bar. Used to remove the border that would be on the left
// side of the table area and extend upwards from the sidebar to
// the left of the table headers (which looks bad because they
// have no border).
.ag-theme-alpine .ag-ltr .ag-side-bar-left {
  border: none;
}
