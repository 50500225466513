.stepper-wrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.faded-stepper {
  position: relative;
}

.faded-stepper::before {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 40px;
  height: 100%;
  background-image: linear-gradient(to left, transparent, white);
  pointer-events: none;
}

.faded-stepper::after {
  content: '';
  display: block;
  position: absolute;
  right: 0;
  width: 40px;
  height: 100%;
  background-image: linear-gradient(to right, transparent, white);
  pointer-events: none;
}

.stepper-list {
  overflow-y: hidden;
  max-width: calc(100vw - 400px);
  width: 100%;
  padding: 0px 20px;
  list-style-type: none;
}
.stepItem {
  display: inline-block;
  margin-left: 1px;
  margin-right: 1px;
  @media (min-width: 1300px) {
    margin-left: 2px;
    margin-right: 2px;
  }

  & span {
    background-color: white;
    position: relative;
    padding: 8px 16px;
    @media (min-width: 1300px) {
      padding: 8px 20px;
    }
    font-size: 16px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
    border-radius: 4px;
    color: #48036A;

    &:hover {
      background-color: rgba(72, 3, 106, 0.05);
    }

    &.active {
      font-weight: 400;
      background-color: rgba(72, 3, 106, 0.05);
    }
  }
}

.stepper-select {
  padding: 0 16px;
  height: 40px;

  a {
    width: 100%;
    text-decoration: none;
    color: inherit;
  }
}

.stepper-select-item {
  width: 100%;
  padding: 8px 16px;
  border-radius: 4px;
  border: 1px solid #48036A;

  a { // Targeting the <a> tag generated by the Next.js Link component
    text-decoration: none;
    color: inherit;
    width: 100%;
  }
}