.ag-header-container {
  text-align: center;
}

.ag-header-group-cell-label,
.ag-header-cell-label {
  justify-content: center;
}

.ag-header-cell-resize:after {
  background-color: transparent !important;
}

.ag-cell {
  border-right: 1px solid lightgrey !important;
}
.ag-cell-value {
  width: 100%;
}
.ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected) {
  border-color: black !important;
}
.ag-cell[aria-selected="true"] {
  border: 1px solid black !important;
}

.ag-react-container {
  height: 100%;
  width: 100%;
}

.highlighted-header-column {
  background: #ece7f2 !important;
}

.highlighted-column {
  background: rgba(72, 3, 106, 0.05) !important;
}

// custom checkbox styles
.check-root {
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 24px;
  margin-bottom: 0;
  height: 24px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.check-root input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 2px;
  height: 18px;
  width: 18px;
  border-radius: 4px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.check-root:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.check-root input:checked ~ .checkmark {
  background-color: #eee;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-root input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.check-root .checkmark:after {
  left: 5px;
  top: 3px;
  width: 8px;
  height: 10px;
  border: solid #212529;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
select.select-root {
  height: 100%;
  // Width set manually in component to fit the cell
  background-color: transparent;
  border: none !important;
  outline: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

option.grid-option {
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  padding: 10px 0px;
  font-size: 14px;
  font-family: inherit;
  width: 100%;
}


.assumption-pill {
  font-size: 12px;
  max-height: 28px;
  padding: 4px;
  margin: 2px 0;
  width: 100%;
  display: flex;
  align-items: center;
  .pill-unit {
    max-height: 28px;
    padding-left: 2px;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  .pill-value {
    display: flex;
    align-items: center;
    padding-right: 2px;
    max-height: 28px;
  }
}

.modal-container {
  min-height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .dropdown-icon {
    color: rgba(200, 200, 200, 0.8);
  }
  span {
    font-size: 12px;
  }
  .highlighted {
    color: black;
  }
  &:hover {
    .dropdown-icon {
      color: black;
    }
  }
}
.popper {
  .popper-content {
    margin-top: 5px;
    margin-left: -17px;
    background: #ffffff;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    min-width: 190px;
  }
}

.header-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.group-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  button {
    margin-left: 4px;
    border-radius: 3px;
    border: 1px solid lightgrey;
    &:hover {
      background-color: rgba(200, 200, 200, 0.8);
    }
    &:disabled {
      background-color: #efefef4d;
    }
  }
}

.add-column-header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  button {
    height: 32px;
    border-radius: 3px;
    border: none;
    background-color: #f8f8f8;
    &:hover {
      background-color: rgba(200, 200, 200, 0.8);
    }
  }
}

.popper-referer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.range-edit-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  input {
    width: 80px;
    border: none;
    outline: none !important;
    background-color: #f8f8f8;
  }
  input:first-child {
    text-align: right;
    margin-right: 4px;
  }
  input:last-child {
    margin-left: 4px;
  }
}
