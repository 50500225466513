.link-tab {
  text-decoration: none !important;
  color: black !important;
  padding: 9px 12px;
  margin-right: 8px;
  border-radius: 4px;
  &__active {
    background-color: rgb(242, 242, 242) !important;
    color: #48036A !important;
  }
  &:hover {
    background-color: rgb(242, 242, 242) !important;
  }
}
