.layout {
  flex: 1;
  display: flex;
  flex-direction: column;

  height: 100%;
  width: 100%;

  .content {
    flex: 1;
    display: block;
    justify-content: center;
    align-items: center;

    width: 100%;
    padding-top: 2rem;

    background: white;
    color: black;
    font-family: 'Karla', sans-serif;

    a {
      color: inherit;
    }
  }
}
