.sider-container {
  position: fixed;
  left: 0;
  width: 70px;
  height: 100vh;
  min-height: 100%;
  z-index: 4;
  transition: width 0.2s;
  -webkit-transition: width 0.2s;
  background-color: #f7f4f9;
  &.open {
    width: 180px;
    .nav-tab span {
      display: block;
    }
    .logo-lg {
      display: block;
    }
    .logo {
      display: none;
    }
  }
  .navs-wrapper {
    padding: 22px 8px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    position: relative;
    left: 14px;
  }
  .logo {
    width: 24px;
    height: 24px;
    margin-bottom: 16px;
  }

  .logo-lg {
    display: none;
    width: 116px;
    margin-bottom: 16px;
  }

  .faded-list {
    position: relative;
  }
  
  .faded-list::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    width: 100%;
    height: 40px;
    background-image: linear-gradient(to top, transparent, #f7f4f9);
    pointer-events: none;
  }
  
  .faded-list::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    background-image: linear-gradient(to bottom, transparent, #f7f4f9);
    pointer-events: none;
  }
  
  .sider-navs {
    list-style-type: none;
    padding: 16px 0;
    overflow: hidden;
    max-height: calc(100vh - 120px);
  }
  
  .sider-nav {
    margin: 16px 0;
  }
  
  @media (max-height: 400px) {
    .sider-navs {
      margin: 8px 0;
    }
    .sider-nav {
      margin: 8px 0;
    }
  }

  .nav-tab {
    display: flex;
    align-items: center;
    text-decoration: none !important;
    color: black;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 22px;
    span {
      margin-left: 8px;
      display: none;
    }
    &:hover {
      background-color: rgba(72, 3, 106, 0.1);
    }
    &__active {
      background-color: rgba(72, 3, 106, 0.1);
    }
  }
}
