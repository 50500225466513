.threedots:after {
  content: "\2807";
  font-size: 25px;
}

.dropdown a:hover {
  text-decoration: none;
}

.add-assumptions {
  padding: 5px 2px;
  cursor: pointer;
}

.assumption-item {
  margin: 2px 0px;
  font-size: 14px;
  cursor: pointer;
  // &:hover {
  //   box-shadow: 0px 0 2px 0px black;
  // }
}

.assumption-name {
  padding: 5px;
  background: #FCFCFC;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  white-space: nowrap;
  // &:hover {
  //   box-shadow: 0px 0 2px 0px black;
  // }
}

.assumption-value {
  padding: 5px;
  background: #FCFCFC;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  font-weight: bold;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  white-space: nowrap;
  // &:hover {
  //     box-shadow: 0px 0 2px 0px black;
  //   }
}

.delivery-select {
  width: 160px;
  padding: 0 5px 0 0;
}

.backdrop {
  color: '#fff';
  z-index: 15000;
}

.scope-error {
  font-size: 16px;
  font-weight: 400;
  color: #20254333;
  text-align: center;
  margin: 0;
}