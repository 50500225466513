.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  .inputs {
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .mainInputs {
    width: 325px;
    padding: 10px 0;
  }
  .parameterDiv {
    display: flex;
    justify-content: space-between;
    align-content: center;
    .revertBtn {
      background-color: white;
      color: blue;
      border: none;
    }
  }
  .rangeInput {
    .inputs {
      width: 200px;
    }
  }

  .sideBar {
    display: flex;
    padding: 20px;
    border-right: 1px solid lightgray;
  }
  .content {
    // max-width: 1240px;
    // width: 1240px;
    display: flex;
    border-top: 1px solid lightgray;
  }
  .leftContent {
    padding: 28px;
  }
  .table {
    padding: 20px 0;
    width: 750px;
    opacity: 0;
  }
  .threedots {
    color: black;
  }
  .tableHeadRow {
    display: flex;
    justify-content: space-between;
  }
  .leftInput {
    margin-bottom: 20px;
  }
  .buildingParameterDiv {
    border: 1px solid lightgray;
    padding: 10px;
  }
  .buildingParameter {
    margin-top: 10px;
    padding: 10px;
    width: 170px;
    border-top: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    border-radius: 5px;
  }
  .buildingParameterDiv {
    padding: 15px;
    border: 1px solid lightgray;
    border-radius: 5px;
  }
  .hcRange {
    display: flex;
    select {
      width: 130px;
    }
  }
}
.AddEstimateSubHeader {
  text-align: center;
  padding: 20px;
  font-weight: 700px;
}
.AddEstimateHeader {
  text-align: center;
  padding-top: 20px;
  font-weight: 700px;
  margin-bottom: 70px;
}
.selectInput {
  padding: 10px 0;
  width: 300px;
}
.footerBtn {
  display: flex;
  justify-content: center;
  padding: 20px;
  border-top: 1px solid gray;
  button {
    margin-right: 20px;
  }
}
