@import "./mui-overrides/button.scss";
@import "./mui-overrides/dialog.scss";

@import "./theme";
@import "./components/header.scss";
@import "./components/layout.scss";
@import "./components/nav-bar.scss";
@import "./components/nav-button.scss";
@import "./components/link-tab.scss";
@import "./components/sider.scss";
@import "./components/stepper.scss";
@import "./components/tree-menu.scss";
@import "./components/tag-field.scss";
@import "./components/toastify.scss";
@import "./components/data-sheet.scss";
@import "./components/switch.scss";
@import "./components/assumptions.scss";
@import "./components/estimated-modal.scss";
@import "./components/cell-components.scss";

* {
  box-sizing: border-box;
}

html,
body,
#__next {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form-control:focus {
  border-color: #6c757d !important;
  box-shadow: none !important;
}
.nav-tabs .nav-link {
  cursor: pointer;
}
.bg-main {
  background-color: #e5e5e5 !important;
}
.btn-primary {
  background-color: #212529;
  color: white;
  border: none;
  outline: none;
  &:hover {
    background-color: #444 !important;
  }
  &:focus {
    box-shadow: none !important;
    background-color: #212529;
  }
}
.btn-main {
  border: none;
  background-color: #e5e5e5;
  color: #000;
  margin-bottom: 0.5rem;
  padding: 5px 15px;
  &:focus {
    box-shadow: none;
  }
}
.btn-black {
  border: none;
  background-color: #212529;
  color: #f8f9fa;
  margin-bottom: 0.5rem;
  padding: 5px 15px;
}
.btn-main:hover {
  background-color: #c5c5c5;
}
.btn-black:hover {
  color: #f8f9fa;
  background-color: #666;
}
.btn-secondary {
  border: none;
  background-color: #c5c5c5;
  color: #000;
  margin-bottom: 0.5rem;
  padding: 5px 15px;
}
.btn-secondary:hover {
  background-color: #b5b5b5;
}
.badge-main {
  background-color: #e5e5e5;
  color: #000;
  padding: 6px;
  font-weight: 300;
}
.font-light {
  font-weight: 200;
}
.font-main-color {
  color: #232533;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid \9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}
.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid \9;
  content: "";
}
.table {
  margin-bottom: 0;

  thead {
    th {
      font-weight: 400;
    }
  }
}
.order {
  float: right;
  position: relative;
  margin-right: 10px;

  .dropdown {
    top: 3px;
    left: -5px;
  }
  .dropup {
    top: -3px;
    left: 0px;
  }
  .dropup,
  .dropdown {
    position: absolute;
  }
}

/** Toggle Style **/
.toggle-wrap {
  max-width: 200px;
  border-radius: 100px;
  background-color: #c5c5c5;
  padding: 3px;

  > div {
    padding: 6px 20px;
    font-size: 1rem;
    text-align: center;
    border-radius: 100px;
    cursor: pointer;
  }
  .active {
    background-color: #fff;
  }
}

.text-divider {
  width: 30%;
  margin: auto;
  padding: 2em 0;
  line-height: 0;
  text-align: center;

  span {
    background-color: #fff;
    padding-left: 4em;
  }

  ::before {
    content: " ";
    display: block;
    border-top: 1px solid #e3e3e3;
    border-bottom: 1px solid #f7f7f7;
  }
}

.multiselect-container {
  .chip {
    background: #6c757d;
  }
}

.icon-plus,
.label-plus {
  cursor: pointer;
  :hover {
    color: black;
  }
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
  left: 50px !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}

.cell-icon {
  right: 10px;
  padding: 0;
  text-align: center;
  display: inline-block;
  font-size: 10px;
  color: #999;
  padding-top: 3px;
  cursor: pointer;
}

.empty-cell {
  color: #999;
  font-style: italic;
}

.tbl-services {
  .btn-plus {
    right: 0;
    padding: 0;
    text-align: center;
    display: inline-block;
    width: 26px;
    border-radius: 13px;
    cursor: pointer;
  }

  .service-cell {
    .btn-close {
      line-height: 11px;
    }
  }
}

.mh-300 {
  min-height: 300px;
}
.searchWrapper {
  border-color: #e5e5e5 !important;
}
.invalid {
  .invalid-feedback {
    display: block;
  }
  .multiselect-container {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

    .searchWrapper {
      border-color: #dc3545;
    }
  }
}

.col-form-label {
  color: #232533;
  font-family: "Karla", sans-serif;
  font-size: 16px;
}

.form-control {
  border: 1px solid #e5e5e5;
  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #c4c4c4;
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c4c4c4;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #c4c4c4;
  }
}

button[data-id="export-btn"] {
  opacity: 0;
}

.markdown-container h1,
.markdown-container h2,
.markdown-container h3,
.markdown-container h4,
.markdown-container h5,
.markdown-container h6 {
  margin-top: 0.5em;
  margin-bottom: 0em;
}

.markdown-container p {
  margin-top: 0em;
  margin-bottom: 0em;
}

