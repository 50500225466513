button {
  &.btn-main {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background-color: transparent;
  }
  &.btn-black {
    background-color: #222;
    color: #fff;
    border-radius: 0;
  }
  &.btn-black:hover {
    background-color: #000;
  }
}
