$ICON_SIZE: 1.5rem;
$DEFAULT_PADDING: 0.75rem;

.rstm-toggle-icon {
  display: inline-block;
  &-symbol {
    width: $ICON_SIZE;
    height: $ICON_SIZE;
    text-align: center;
    line-height: $ICON_SIZE;
  }
}

.rstm-tree-item-group {
  list-style-type: none;
  padding-left: 0;
  border-top: 1px solid #ccc;
  text-align: left;
  width: 100%;
  margin: 0
}

.rstm-tree-item {
  padding: 0.75rem;
  cursor: pointer;
  color: #333;
  background: none;
  border-bottom: 1px solid #ccc;
  box-shadow: none;
  z-index: unset;
  position: relative;
  display: flex;
}

.rstm-search {
  padding: 1rem 1.5rem;
  border: none;
  width: 100%;
}
