// theme/_colors.scss

$primary: #FFFFFF;
$primary-light: #ffffff;
$primary-dark: #f1f1f1;

$secondary: #2a3747;
$secondary-light: #546172;
$secondary-dark: #011120;

$on-primary: #000;
$on-primary-light: #303030;
$on-secondary: #fff;

$highlight: #FA4141;
