.tag-field {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .tag-input {
    min-width: 200px;
    font-size: 16px;
    height: fit-content;
    margin: 8px;
  }
}
.tag {
  display: flex;
  align-items: center;
  height: fit-content;
  border-radius: 4px;
  padding: 4px 8px;
  margin: 4px;
  font-size: 14px;

  background-color: rgba(32, 37, 67, 0.05);

  &__input {
    background-color: rgba(92, 194, 255, 0.1);
  }
  &__ratecard {
    background-color: rgba(102, 45, 145, 0.1);
  }
  &__variables {
    background-color: rgba(32, 137, 37, 0.1);
  }
  &__building-column {
    background-color: rgba(32, 137, 137, 0.1);
  }
  &__operator {
    background-color: transparent;
  }
  .tag-close {
    display: none;
    font-size: 16px;
    cursor: pointer;
  }

  &:hover {
    .tag-close {
      margin-left: 6px;
      display: block;
    }
  }
}
