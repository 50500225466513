.navBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  height: 60px;
  width: 100%;
  padding: 5px 0;

  background: $primary;

  font-family: $core-font;
  color: $on-primary-light;
  font-weight: 500;

  a {
    color: inherit;
    text-decoration: none;
  }

  .active {
    color: $highlight;
  }
}